const emoji = [
    "|∀ﾟ",
    "(´ﾟДﾟ`)",
    "(;´Д`)",
    "(｀･ω･)",
    "(=ﾟωﾟ)=",
    "| ω・´)",
    "|-` )",
    "|д` )",
    "|ー` )",
    "|∀` )",
    "(つд⊂)",
    "(ﾟДﾟ≡ﾟДﾟ)",
    "(＾o＾)ﾉ",
    "(|||ﾟДﾟ)",
    "( ﾟ∀ﾟ)",
    "( ´∀`)",
    "(*´∀`)",
    "(*ﾟ∇ﾟ)",
    "(*ﾟーﾟ)",
    "(　ﾟ 3ﾟ)",
    "( ´ー`)",
    "( ・_ゝ・)",
    "( ´_ゝ`)",
    "(*´д`)",
    "(・ー・)",
    "(・∀・)",
    "(ゝ∀･)",
    "(〃∀〃)",
    "(*ﾟ∀ﾟ*)",
    "( ﾟ∀。)",
    "( `д´)",
    "(`ε´ )",
    "(`ヮ´ )",
    "σ`∀´)",
    "ﾟ∀ﾟ)σ",
    "ﾟ ∀ﾟ)ノ",
    "(╬ﾟдﾟ)",
    "(|||ﾟдﾟ)",
    "( ﾟдﾟ)",
    "Σ( ﾟдﾟ)",
    "( ;ﾟдﾟ)",
    "( ;´д`)",
    "(　д ) ﾟ ﾟ",
    "( ☉д⊙)",
    "(　ﾟдﾟ)))",
    "( ` ・´)",
    "( ´д`)",
    "( -д-)",
    "(>д<)",
    "･ﾟ( ﾉд`ﾟ)",
    "( TдT)",
    "(￣∇￣)",
    "(￣3￣)",
    "(￣ｰ￣)",
    "(￣ . ￣)",
    "(￣皿￣)",
    "(￣艸￣)",
    "(￣︿￣)",
    "(￣︶￣)",
    "ヾ(´ωﾟ｀)",
    "(*´ω`*)",
    "(・ω・)",
    "( ´・ω)",
    "(｀・ω)",
    "(´・ω・`)",
    "(`・ω・´)",
    "( `_っ´)",
    "( `ー´)",
    "( ´_っ`)",
    "( ´ρ`)",
    "( ﾟωﾟ)",
    "(oﾟωﾟo)",
    "(　^ω^)",
    "(｡◕∀◕｡)",
    "/( ◕‿‿◕ )",
    "\\ヾ(´ε`ヾ)",
    "(ノﾟ∀ﾟ)ノ",
    "(σﾟдﾟ)σ",
    "(σﾟ∀ﾟ)σ",
    "|дﾟ )",
    "┃電柱┃",
    "ﾟ(つд`ﾟ)",
    "ﾟÅﾟ )",
    "⊂彡☆))д`)",
    "⊂彡☆))д´)",
    "⊂彡☆))∀`)",
    "(´∀(☆ミつ",
    "･ﾟ( ﾉヮ´ )",
    "(ﾉ)`ω´(ヾ)",
    "ᕕ( ᐛ )ᕗ",
    "(　ˇωˇ)",
    "( ｣ﾟДﾟ)｣＜",
    "( ›´ω`‹ )",
    "(;´ヮ`)7",
    "(`ゥ´ )",
    "(`ᝫ´ )",
    "( ᑭ`д´)ᓀ))д´)ᑫ",
    "σ( ᑒ )",
    "(`ヮ´ )σ`∀´) ﾟ∀ﾟ)σ"
]
export default emoji